::selection {
  background-color: #c48411;
}

/* custom input */
.colorInput {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
  border: 1px solid #d9d9d9;
}

.colorInput input {
  margin: 0;
  padding: 0;
  border: 0;
  background: unset;
}
